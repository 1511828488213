import { createContext } from "react";

export interface FormContextData {
    formAlias:string;
    formData:Map<string, string>;
    updateFormData(key:string, value:string):void;
    bulkUpdateFormData(values:{key:string; value:string}[]):void;
    removeAndReplaceFormData(remove:string, replace:{key:string, value:string}[]):void;
    removeFormData(key:string):void;
    updateForm():void;
    nextStep():void;
    prevStep():void;
    setFinished(finished:string):void;
    formRevision:number;
  }
   
  // export const formContextDefaultValue: FormContextData = {
  //   // formData: new Map<String, String>();
  //   // useFormItemChange: null;
  // }
   
  export const FormContext = createContext<Partial<FormContextData>>({});