import React, { Fragment, FunctionComponent, useContext, useEffect, useState } from 'react';
import './ChristianFinancialResoucesTheme.scss';

interface IProps {
  resetApp():void;
}


const ChristianFinancialResoucesTheme:FunctionComponent<IProps> = props => {

  return (
    <Fragment> 
    <div className="app-wrapper cfr">
    <div className="App">
      <div className="header">
        <div className="header-body">
          <img src="https://www.cfrministry.org/res/images/CFR-Logo-Horizontal-ForOnDark-large.png" />
          <div className={"header-buttons"}>
            {<a onClick={props.resetApp}>Reset Application</a>} <a href="#modal-help">Help</a>
          </div>
        </div>
         
      </div>
      
    </div>
       {props.children}
    </div>
    <div className="page-footer">
    Please note that this is neither an offer to sell nor a solicitation of an offer to buy Christian Financial Resources securities. Such an offer is made by an Offering Circular and only in those states where Christian Financial Resources securities may lawfully be offered or sold. Christian Financial Resources securities are subject to certain risk factors as described in the Offering Circular and are not F.D.I.C. or S.I.P.C. insured nor are they bank deposits. NOTE: CFR has the right to call Certificates for redemption at any time upon sixty (60) days written notice. In such event, interest will be paid on the date of redemption.

© 2021 Christian Financial Resources, Inc. <a href="https://www.cfrministry.org/privacy/">Privacy Policy</a>
    </div>
    </Fragment>
    )
}

export default ChristianFinancialResoucesTheme;