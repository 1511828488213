import React, { FunctionComponent, Fragment, useContext, useEffect, useState, useRef, ChangeEvent } from 'react';
import Product from '../../RateChart/interface/Product';
import InvestmentProduct from '../interfaces/InvestmentProduct';
import InvestmentAmountInput from '../InvestmentAmountInput';
import InvestmentProductSelect from '../InvestmentProductSelect';
import TrashIcon from '../trash-icon.svg';
interface IProps {
    index:number;
    investmentProduct:InvestmentProduct;
    investmentOptions:Product[];
    updateInvestmentProduct(investmentProduct:InvestmentProduct, index:number):void;
    removeInvestment(index:number):void;
}

const InvestmentListItem:FunctionComponent<IProps> = props => {

  

  function updateSelection(value:string) {
    let updatedInvestmentProduct = props.investmentProduct;
    updatedInvestmentProduct.product = props.investmentOptions.filter((product:Product) => product.name === value)[0];
    props.updateInvestmentProduct(updatedInvestmentProduct, props.index);
  }

  function updateAmount(amount:string) {
    let updatedInvestmentProduct = props.investmentProduct;
    updatedInvestmentProduct.amount = amount;
    props.updateInvestmentProduct(updatedInvestmentProduct, props.index);
  }

  return (
    <>
    <div className='investment-product'>
                        <InvestmentProductSelect index={props.index} investmentItem={props.investmentProduct} investmentOptions={props.investmentOptions} updateSelection={updateSelection} />
                        <InvestmentAmountInput investmentItem={props.investmentProduct} investmentOptions={props.investmentOptions} updateAmount={updateAmount} />
                        <div className='investment-product-option'>
                            {props.index > 0 && <button onClick={() => props.removeInvestment(props.index)}>
                                <img src={TrashIcon} />
                            </button>}
                        </div>
                    </div>
    </>
  );
}

export default InvestmentListItem;