import React, { Component, FunctionComponent, useEffect, useState, useContext } from 'react';
import axios from 'axios';
import './FormItemBigVerticalButtons.scss';
import IFormItemBigVerticalButtons from '../../../interfaces/FormItemBigVerticalButtons';
import FormItemChoice from '../../../interfaces/FormItemChoice';
import BigVerticalButton from './BigVerticalButton';
import Group from '../interfaces/Group';
import { group } from 'console';
import { FormContext } from '../../../context/FormContext';
import KeyReplace from '../../KeyReplace';

interface IProps {
  component:IFormItemBigVerticalButtons;
  parentAlias:string;
  checkedValue:string;
  onValueChange(value:string):void;
}

const FormItemBigVerticalButtons:FunctionComponent<IProps> = props => {

  const formContext = useContext(FormContext);

  function showIf(formItem:any):boolean {
    
    // let show = true;
     let showList:boolean[] = [];
     let multitype = '';
     
     if(formItem.hasOwnProperty("showIf")) {
       let expressions:string[] = [];
       
       if(formItem.showIf.includes('||')) {
         
         multitype = "or";
         expressions = formItem.showIf.split("||");
       }else if(formItem.showIf.includes('&&')) {
         
         multitype = "and";
         expressions = formItem.showIf.split("&&");
       } else {
         expressions = formItem.showIf.split("||");
       }
       
       //console.log(expressions);
       expressions.forEach((expression:string) => 
      { let expressionArray:string[] = expression.split(" ");
       //lets extract the fields
       let show = false;
       let value = formContext.formData?.get(formContext.formAlias + '.' + expressionArray[0]);
       value = value?.toLowerCase();
       let compareValue = expressionArray[2].replaceAll("+", " ");
       compareValue = compareValue.toLowerCase();
       //console.log(value + " " + expressionArray[1] + " " + compareValue);
       if(expressionArray[1] === 'eq') {
         
         if(value === compareValue) {
           show = true;
         } 
       }
       if(expressionArray[1] === 'neq') {
         
         if(value != compareValue) {
           show = true;
         } 
       }
       if(expressionArray[1] === 'gte') {
         
         if(Number(value) >= Number(compareValue)) {
           show = true;
         } 
       }
         showList.push(show);
       }
       );
 
 
       
       
     }
     
     if(multitype != '') {
      
      if (multitype === 'or') {
        let final:boolean = false;
        showList.forEach((value:boolean) => {
          if(value) {
            final = true;
          }
        })
        return final;
      }
      if (multitype === 'and') {
        let final:boolean = true;
        showList.forEach((value:boolean) => {
          if(!value) {
            final = false;
          }
        })
        return final;
      }
    }
     if (showList.length === 0) {
       return true;
     }
     return showList[0];
   }

  function isGroupEmpty(group:Group):boolean {
    let isEmpty = true;
    props.component.choices.filter((choice:FormItemChoice) => choice.groupId === group.id).forEach((choice:FormItemChoice) => 
      {if(showIf(choice)) {
        isEmpty = false;
      }}
    )
    
    return isEmpty;
  }

  return (
    <div className="big-vertical-buttons">
      <ul>
        {!props.component.groups && props.component.choices.map((choice:FormItemChoice, index:number) => 
            showIf(choice) && 
            <li key={props.component.alias + "-" + index}><BigVerticalButton checkedValue={props.checkedValue} count={index + 1} name={props.parentAlias + "." + props.component.alias} onValueChange={props.onValueChange} choice={choice} /></li>
        )}
        </ul>
        {props.component.groups && 
          props.component.groups.map((group:Group, indexParent:number) => 
            !isGroupEmpty(group) &&
            <div key={group.id + '-' + indexParent} className="big-vertical-button-group">
            <h2>{group.name}</h2>
            <ul>
            {props.component.choices.filter((choice:FormItemChoice) => choice.groupId === group.id)
            .map((choice:FormItemChoice, index:number) => 
              showIf(choice) && 
              <li key={props.component.alias + "-" + index}><BigVerticalButton checkedValue={props.checkedValue} count={(indexParent * 100) + index + 1} name={props.parentAlias + "." + props.component.alias} onValueChange={props.onValueChange} choice={choice} /></li>
              
              )}
            </ul>
            {group.footnote && <div className="footnote"><KeyReplace string={group.footnote} /></div>}
            </div>
          )
        }
    </div>
  );
}

export default FormItemBigVerticalButtons;