import React, { FunctionComponent, Fragment, useContext, useEffect, useState, useRef, ChangeEvent } from 'react';
import Product from '../../RateChart/interface/Product';
import InvestmentProduct from '../interfaces/InvestmentProduct';

interface IProps {
    index:number;
    investmentItem:InvestmentProduct;
    investmentOptions:Product[];
    updateSelection(value:string,index:number):void;
}

const InvestmentProductSelect: FunctionComponent<IProps> = props => {

    let [isTouched, setIsTouched] = useState<boolean>(false);

    function getApy(investment:InvestmentProduct) {
       
    
        if(investment.product) {
          let apy = investment.product.rates[0].apy
          return apy === 0 ? "flexible" : parseFloat(String(apy)).toFixed(2) + "% APY";
        } else {
          return "";
        }
        
      }

      function onValueChange(event:React.ChangeEvent<HTMLSelectElement>) {
        let value = event.target.value;
        props.updateSelection(value, props.index);
      }

    return (
        <div className='investment-product-select'>
            <label><span className='block-label'>Investment</span>
                <div className={'select-wrapper ' + (isTouched && !props.investmentItem.product ? 'error' : '')}>
                    <select onChange={(event) => onValueChange(event)} value={props.investmentItem.product?.name || ""}>
                        <option value=''>Select investment</option>
                        {props.investmentOptions.map((product: Product) =>
                            <option key={product.id} value={product.name}>{product.name}</option>
                        )}
                    </select>
                    {props.investmentItem.product && <div className='interest-display'>
                        Starting at {getApy(props.investmentItem)}
                    </div>}
                </div>
            </label>
        </div>
    );
}

export default InvestmentProductSelect;