import { FormContextData } from '../context/FormContext';

export default class VisibilityService {
	


    static showIf(formItem:any, formContext:Partial<FormContextData>):boolean {
    
        // let show = true;
         let showList:boolean[] = [];
         let multitype = '';
         
         if(formItem.hasOwnProperty("showIf")) {
           let expressions:string[] = [];
         
           if(formItem.showIf.includes('||')) {
             multitype = "or";
             expressions = formItem.showIf.split("||");
           }else if(formItem.showIf.includes('&&')) {
             multitype = "and";
             expressions = formItem.showIf.split("&&");
           } else {
             expressions = formItem.showIf.split("||");
           }
           
           //console.log(expressions);
           expressions.forEach((expression:string) => 
          { let expressionArray:string[] = expression.split(" ");
           //lets extract the fields
           let show = false;
           let value;
       if(expressionArray[0].startsWith('system')) {
        value = formContext.formData?.get(expressionArray[0]);
       } else {
        value = formContext.formData?.get(formContext.formAlias + '.' + expressionArray[0]);
       }
       
           value = value?.toLowerCase();
           if(expressionArray.length > 2) {
            let replaced = false;
            let compareValue;
            if(expressionArray[2].startsWith('system')) {
              compareValue = formContext.formData?.get(expressionArray[2]);
             } else {
              compareValue = formContext.formData?.get(formContext.formAlias + '.' + expressionArray[2]);
             }
             if(!compareValue) {
              compareValue = expressionArray[2].replaceAll("+", " ");
             } else {
              replaced = true;
             }
                
                compareValue = compareValue?.toLowerCase();
            
   
            
            if(expressionArray[1] === 'eq') {
                
                if(value === compareValue) {
                show = true;
                } 
            }
            if(expressionArray[1] === 'neq') {
                
                if(value != compareValue) {
                show = true;
                } 
            }
            if(expressionArray[1] === 'gte') {
                
                if(Number(value) >= Number(compareValue)) {
                show = true;
                } 
            }
            if(expressionArray[1] === 'includes') {
              if (!replaced) {
                if(formItem.hasOwnProperty("showIfDefault")) {
                  show = formItem.showIfDefault;
                } else {
                  show = false;
                }
                
              } else {
                let valueArray = value?.split(",") || [];
                if(valueArray.includes(compareValue)) {
                  show = true;
                }
              }
            }
            if(expressionArray[1] === 'excludes') {
              if (!replaced) {
                if(formItem.hasOwnProperty("showIfDefault")) {
                  show = formItem.showIfDefault;
                } else {
                  show = false;
                }
              } else {
                let valueArray = value?.split(",") || [];
                if(!valueArray.includes(compareValue)) {
                  show = true;
                }
              }
              
            }
           }

           
           if (expressionArray.length === 2) {
            if(expressionArray[1] === 'isEmpty') {
                
                if(!value) {
                show = true;
                } 
            }
            if(expressionArray[1] === 'isNotEmpty') {
                
                if(value) {
                show = true;
                } 
            }
           }
           
             showList.push(show);
           }
           );
     
     
           
           
         }
         
         if(multitype != '') {
           
           if (multitype === 'or') {
             let final:boolean = false;
             showList.forEach((value:boolean) => {
               if(value) {
                 final = true;
               }
             })
             return final;
           }
           if (multitype === 'and') {
             let final:boolean = true;
             showList.forEach((value:boolean) => {
               if(!value) {
                 final = false;
               }
             })
             return final;
           }
         }
         
         if (showList.length === 0) {
           return true;
         }
     
         return showList[0];
       }


       static disableIf(formItem:any, formContext:Partial<FormContextData>):boolean {
    
        // let show = true;
         let showList:boolean[] = [];
         let multitype = '';
         
         if(formItem.hasOwnProperty("disableIf")) {
           let expressions:string[] = [];
         
           if(formItem.disableIf.includes('||')) {
             multitype = "or";
             expressions = formItem.disableIf.split("||");
           }else if(formItem.disableIf.includes('&&')) {
             multitype = "and";
             expressions = formItem.disableIf.split("&&");
           } else {
             expressions = formItem.disableIf.split("||");
           }
           
           //console.log(expressions);
           expressions.forEach((expression:string) => 
          { let expressionArray:string[] = expression.split(" ");
           //lets extract the fields
           let show = false;
           let value;
       if(expressionArray[0].startsWith('system')) {
        value = formContext.formData?.get(expressionArray[0]);
       } else {
        value = formContext.formData?.get(formContext.formAlias + '.' + expressionArray[0]);
       }
       
           value = value?.toLowerCase();
           if(expressionArray.length > 2) {
                let compareValue = expressionArray[2].replaceAll("+", " ");
            compareValue = compareValue.toLowerCase();
            
   
            
            if(expressionArray[1] === 'eq') {
                
                if(value === compareValue) {
                show = true;
                } 
            }
            if(expressionArray[1] === 'neq') {
                
                if(value != compareValue) {
                show = true;
                } 
            }
            if(expressionArray[1] === 'gte') {
                
                if(Number(value) >= Number(compareValue)) {
                show = true;
                } 
            }
           }
           if (expressionArray.length === 2) {
            if(expressionArray[1] === 'isEmpty') {
                
                if(!value) {
                show = true;
                } 
            }
            if(expressionArray[1] === 'isNotEmpty') {
                
                if(value) {
                show = true;
                } 
            }
           }
           
             showList.push(show);
           }
           );
     
     
           
           
         }
         
         if(multitype != '') {
           
           if (multitype === 'or') {
             let final:boolean = false;
             showList.forEach((value:boolean) => {
               if(value) {
                 final = true;
               }
             })
             return final;
           }
           if (multitype === 'and') {
             let final:boolean = true;
             showList.forEach((value:boolean) => {
               if(!value) {
                 final = false;
               }
             })
             return final;
           }
         }
         
         if (showList.length === 0) {
           return true;
         }
     
         return showList[0];
       }
}
