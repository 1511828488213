import { stringify } from 'querystring';
import React, { FunctionComponent, Fragment, useContext, useEffect, useState, useRef, ChangeEvent } from 'react';
import axios from 'axios';
import { FormContext } from '../../../context/FormContext';
import './InvestmentMultiSelect.scss';
import KeyReplace from '../../KeyReplace';
import { getMaxListeners } from 'process';
import Product from '../RateChart/interface/Product';
import Rate from '../RateChart/interface/Rate';

import IInvestmentMultiSelect from '../interfaces/InvestmentMultiSelect';
import InvestmentListItem from './InvestmentListItem';
import InvestmentProduct from './interfaces/InvestmentProduct';

interface IProps {
  component: IInvestmentMultiSelect;
  parentAlias: string;
  formDirty: boolean;
  validationHandler(alias: string, isValid: boolean): void;
}




const InvestmentMultiSelect: FunctionComponent<IProps> = props => {



  const formContext = useContext(FormContext);

  const rateChartDiv = React.useRef<HTMLInputElement>(null);
  let [isTouched, setIsTouched] = useState<boolean>(false);
  let [itemCount, setItemCount] = useState<number>(1);
  let [rateChart, setRateChart] = useState<Product[]>([]);
  let [unfilteredChart, setUnfilteredChart] = useState<Product[]>([]);
  let [investment, setInvestment] = useState<Product>();
  const [chartOpen, setChartOpen] = useState<boolean>(false);
  const [investmentList, setInvestmentList] = useState<InvestmentProduct[]>([]);
  const [filter, setFilter] = useState<String>("");

  const selectedState = formContext.formData?.get('system.primaryState');

  function getAlias(): string {
    return props.parentAlias + '.' + props.component.alias + '.' + props.component.alias;
  }

  function getComponentRootAlias(): string {
    return props.parentAlias + '.' + props.component.alias;
  }

  function addInvestment() {
    let itemNumber = itemCount + 1;
    setItemCount(itemNumber);
    let updatedInvestments = investmentList;
    updatedInvestments.push({ count: itemNumber, product: null, amount: null })
    setInvestmentList([...updatedInvestments]);
  }

  function removeInvestment(index: number) {
    let updatedInvestments = investmentList;
    updatedInvestments.splice(index, 1);
    //console.log(updatedInvestments);
    if (formContext.removeFormData && formContext.formData) {
        for (let [key, value] of formContext.formData.entries()) {
          if (key.startsWith(getAlias())) {
            formContext.removeFormData(key);
          }
        }
      }
    setInvestmentList([...updatedInvestments]);
    // if(formContext.removeFormData) {
    //   formContext.removeFormData(getAlias() + "-" + (index + 1) + ".name");
    //   formContext.removeFormData(getAlias() + "-" + (index + 1) + ".amount");
    //   formContext.removeFormData(getAlias() + "-" + (index + 1) + ".type");
    // }
  }




  const fetchData = React.useCallback(() => {
    axios({
      "method": "GET",
      //"url": "//localhost:8080/formbuilder-api/getRateChartRates",
      "url": `//${process.env.REACT_APP_CIF_SERVICE_BASEPATH}/getRateChartRates`
    })
      .then((response) => {
        //console.log(response.data);
        let products: Product[] = response.data;
        setUnfilteredChart(products);
        //console.log(products);
        if (props.component.rateFilter) {
          let filter = formContext.formData?.get(props.component.rateFilter || "") || "";
          setFilter(filter);
          products = products.filter((product: Product) => product.tag.includes(filter || ""));
        }

        setRateChart(products);
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])


  React.useEffect(() => {
    fetchData()
  }, [fetchData])

  React.useEffect(() => {
    // let investmentChoice = formContext.formData?.get("investment-application.choose-investment.investment-select");
    setInvestment(rateChart[0])

  }, [formContext.formRevision, rateChart])




  function updateInvestmentProduct(investmentProduct: InvestmentProduct, index: number) {
    let updatedInvestments = investmentList;
    updatedInvestments[index] = investmentProduct;
    setInvestmentList([...updatedInvestments]);
  }

  function isAmountValid(product: InvestmentProduct): boolean {
    let valid = true;
    let minimumInvestment = parseFloat(getMinimum(product).replace(/,/g, ''));
    let amount = parseFloat((product.amount || "0").replace(/,/g, ''));

    if (amount < minimumInvestment) {
      valid = false;
    }
    return valid;
  }

  React.useEffect(() => {
    let valid = true;
    for (let i = 0; i < investmentList.length; i++) {
      
      if (formContext.updateFormData) {
        formContext.updateFormData(getAlias() + "-" + (i + 1) + ".name", investmentList[i].product?.name || "");
        formContext.updateFormData(getAlias() + "-" + (i + 1) + ".amount", investmentList[i].amount || "");
        formContext.updateFormData(getAlias() + "-" + (i + 1) + ".type", investmentList[i].product?.type || "");
        formContext.updateFormData(getAlias() + "-" + (i + 1) + ".duration", investmentList[i].product?.duration.toString() || "");
      }
      if (!investmentList[i].product) {
        valid = false;

      } else {
        if (!isAmountValid(investmentList[i])) {
          valid = false;
        }
      }

    }
    if (formContext.updateFormData) {
      formContext.updateFormData("system.total", getInvestmentSum());
    }
    // console.log(valid);
    props.validationHandler(getAlias(), valid);
    if (valid) {
      setIsTouched(false);
    }

  }, [investmentList])

  React.useEffect(() => {
    if (rateChart.length > 0) {
      setInvestmentList(getInitialInvestmentSelection());
    }



  }, [rateChart])

  React.useEffect(() => {
    if (props.formDirty && !isTouched) {
      setIsTouched(true);
      //validate();
    }

  }, [props.formDirty, isTouched])

  React.useEffect(() => {
    if (props.component.rateFilter) {
      let newfilter = formContext.formData?.get(props.component.rateFilter || "") || "";
      if (newfilter != filter) {
        let products = unfilteredChart.filter((product: Product) => product.tag.includes(newfilter || ""));
        setFilter(newfilter);
        setRateChart([...products]);
      }

    }


  }, [formContext.formRevision])

  function getInitialInvestmentSelection(): InvestmentProduct[] {
    let foundInvestments = []
    let count = 0
    while (true) {
      count = count + 1;
      let investmentName = formContext.formData?.get(getAlias() + '-' + count + '.name');
      let investmentAmount = formContext.formData?.get(getAlias() + '-' + count + '.amount') || null;
      
      let foundProduct = rateChart.filter((product: Product) => product.name == investmentName)[0];

      if (foundProduct) {
        let foundInvestmentProduct = {} as InvestmentProduct;
        foundInvestmentProduct.count = count;
        foundInvestmentProduct.amount = investmentAmount;
        foundInvestmentProduct.product = foundProduct;
        foundInvestments.push(foundInvestmentProduct);
      } else {
        break;
      }
    }
    setItemCount(count);
    if (foundInvestments.length > 0) {
      return foundInvestments
    } else {
      return [{ count: itemCount, product: null, amount: null }]
    }
  }





  function getMinimum(investment: InvestmentProduct) {
    if (investment.product) {
      let min = investment.product.rates[0].minimum;
      return addComma(String(min));
    } else {
      return "";
    }
  }

  function getMaxAmount(index: number): string {
    let next = investment?.rates[index + 1];
    if (next) {
      return formatter.format(next.minimum - 1);
    } else {
      return "+";
    }
  }

  function addComma(string: string) {
    return string.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  function getInvestmentSum() {

    let total = 0;
    for (let i = 0; i < investmentList.length; i++) {
      if (investmentList[i].amount) {
        let amount = investmentList[i].amount || "0";
        let numberAmount = parseFloat(amount.replaceAll(",", ""));
        total += numberAmount;
      }
    }
    
    
    return addComma(String(total));
  }



  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  const filteredRateChart = rateChart.filter(product => !product.tag.includes(`hide-state-${selectedState?.toLowerCase()}`));
  //const rateChartUnavailable = rateChart.filter(product => product.tag.includes(`hide-state-${selectedState?.toLowerCase()}`));


  return (
    <>
      {Boolean(rateChart.length > 0) &&
        <div>
          <div className='inline-rate-viewer'>
            <div className='rate-box'>
              <button className={"toggle " + (chartOpen ? "open" : '')} onClick={() => setChartOpen(!chartOpen)}>View our current rates</button>
              <div className='rate-wrapper' style={{ height: (chartOpen ? rateChartDiv.current?.offsetHeight : 0), opacity: (chartOpen ? 1 : 0) }}>
                <div className='rate-pane' ref={rateChartDiv}>
                  <table>
                    <thead>
                      <tr>
                        <th className='align-left'>Type</th>
                        <th>Min Investment</th>
                        <th>Interest Rate (APR)</th>
                        <th>Interest Yield (APY)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {rateChart.map((product: Product) =>
                        <Fragment key={'product-' + product.id}>
                          {product.rates.map((rate: Rate, index: number) =>
                            <tr className={(index === 0 ? "product-row-start" : "") + (product.tag.includes(`hide-state-${selectedState?.toLowerCase()}`) ? " disabled" : "")} key={'rate-' + index}>
                              {index === 0 && <td className='row-title' rowSpan={product.rates.length}>{product.name} {product.tag.includes('special') && <span className='special'>Special Rate</span>} {product.tag.includes(`hide-state-${selectedState?.toLowerCase()}`) && <span className='not-available'>Not available in your state</span>}</td>}
                              <td>{formatter.format(rate.minimum)}</td>
                              <td>{rate.rate === 0 ? "flexible" : parseFloat(String(rate.rate)).toFixed(2) + "%"}</td>
                              <td>{rate.apy === 0 ? "flexible" : parseFloat(String(rate.apy)).toFixed(2) + "%"}</td>
                            </tr>
                          )}
                        </Fragment>
                      )}

                    </tbody>
                  </table>
                  <div className="foot-note">Investment rates are established according to policies set forth in the Offering Circular and are subject to change without notice.<br />
                  </div>
                </div>
              </div>
            </div>
            <div className='rate-builder'>
              <ul>
                {investmentList.map((investment: InvestmentProduct, index: number) =>
                  <li key={investment.count}>
                    <InvestmentListItem index={index} investmentOptions={filteredRateChart} updateInvestmentProduct={updateInvestmentProduct} removeInvestment={removeInvestment} investmentProduct={investment} />
                  </li>)}
              </ul>
              {investmentList.length < 5 && <button className='add' onClick={addInvestment}>Add another investment</button>}

              <div className='investment-total'>
                <dl>
                  <dt>Total:</dt>
                  <dd>${getInvestmentSum()}.00</dd>
                </dl>
              </div>
            </div>
          </div>
        </div>}
    </>
  );
}

export default InvestmentMultiSelect;