import React, { Component, FunctionComponent, useEffect, useState, useContext } from 'react';
import axios from 'axios';
import './FormItemRadioButtons.scss';
import IFormItemRadioButtons from '../../../interfaces/FormItemRadioButtons';
import FormItemChoice from '../../../interfaces/FormItemChoice';
import BigVerticalButton from './RadioButton';
import Group from '../interfaces/Group';
import { group } from 'console';
import { FormContext } from '../../../context/FormContext';
import KeyReplace from '../../KeyReplace';
import VisibilityService from '../../../service/VisibilityService';
import RadioButton from './RadioButton';

interface IProps {
  component:IFormItemRadioButtons;
  parentAlias:string;
  checkedValue:string;
  onValueChange(value:string):void;
}

const FormItemRadioButtons:FunctionComponent<IProps> = props => {

  const formContext = useContext(FormContext);

  function showIfWrapper(formItem: any): boolean {
    if (formItem.showIf?.includes("this")) {
      let copy = JSON.parse(JSON.stringify(formItem));
      copy.showIf = copy.showIf.replaceAll("this", props.parentAlias).replaceAll(formContext.formAlias + ".", "");
      let show = VisibilityService.showIf(copy, formContext);

      return show;
    } else {
      let show = VisibilityService.showIf(formItem, formContext);

      return show;
    }

  }

  

  function isGroupEmpty(group:Group):boolean {
    let isEmpty = true;
    props.component.choices.filter((choice:FormItemChoice) => choice.groupId === group.id).forEach((choice:FormItemChoice) => 
      {if(showIfWrapper(choice)) {
        isEmpty = false;
      }}
    )
    
    return isEmpty;
  }

  return (
    <div className="radio-buttons">
      <fieldset>
        <legend><KeyReplace string={props.component.prompt || ''} />{props.component.required && <span className="required">*</span>}</legend>
        <div className='radio-wrapper-set'>
        {!props.component.groups && props.component.choices.map((choice:FormItemChoice, index:number) => 
            showIfWrapper(choice) && 
            <div className='radio-wrapper' key={props.component.alias + "-" + index}><RadioButton checkedValue={props.checkedValue} count={index + 1} name={props.parentAlias + "." + props.component.alias} onValueChange={props.onValueChange} choice={choice} /></div>
        )}
        </div>
        </fieldset>
        {props.component.groups && 
          props.component.groups.map((group:Group, indexParent:number) => 
            !isGroupEmpty(group) &&
            <div key={group.id + '-' + indexParent} className="radio-button-group">
            <h2>{group.name}</h2>
            <ul>
            {props.component.choices.filter((choice:FormItemChoice) => choice.groupId === group.id)
            .map((choice:FormItemChoice, index:number) => 
              showIfWrapper(choice) && 
              <li key={props.component.alias + "-" + index}><RadioButton checkedValue={props.checkedValue} count={(indexParent * 100) + index + 1} name={props.parentAlias + "." + props.component.alias} onValueChange={props.onValueChange} choice={choice} /></li>
              
              )}
            </ul>
            {group.footnote && <div className="footnote"><KeyReplace string={group.footnote} /></div>}
            </div>
          )
        }
    </div>
  );
}

export default FormItemRadioButtons;