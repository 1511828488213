import React, { Component, FunctionComponent, useContext, useEffect, useState, Fragment } from 'react';
import axios from 'axios';
import './FieldSet.scss';
import IFieldSet from '../interfaces/FieldSet';
import FormItemRenderer from '../../FormItemRenderer/FormItemRenderer';
import { fileURLToPath } from 'url';
import { FormContext } from '../../../context/FormContext';
import KeyReplace from '../../KeyReplace';

interface IProps {
  component:IFieldSet;
  parentAlias:string;
  count?:number;
  percentage?:number;
  formDirty:boolean;
  validationHandler(alias:string, isValid:boolean):void;
  removeValidationHandler(alias:string):void;
}


const FieldSet:FunctionComponent<IProps> = props => {
  
  const formContext = useContext(FormContext);
  

  return (
    <div className="field-set">
        {props.component.label && <h2><KeyReplace string={props.component.label} count={props.count} /></h2>}
        {props.component.prompt && <p><KeyReplace string={props.component.prompt} /></p>}
        {props.component.formItems.map((formItem:any, index:number) =>
          <Fragment key={props.component.alias + "-" + index}><FormItemRenderer formDirty={props.formDirty} removeValidationHandler={props.removeValidationHandler} validationHandler={props.validationHandler} parentAlias={props.parentAlias + "." + props.component.alias} formItem={formItem} percentage={props.percentage} /></Fragment>
        )}
    </div>
  );
}

export default FieldSet;