import React, { FunctionComponent, useEffect, useCallback, useState, useContext, Component, Fragment } from 'react';
import axios from 'axios';
import './AccountVerification.scss';
import ISelectOne from '../interfaces/SelectOne';
import FormItemChoice from '../../../interfaces/FormItemChoice';
import FormItemBigImageButtons from '../FormItemBigImageButtons';
import FormItemBigVerticalButtons from '../FormItemBigVerticalButtons';
import { FormContext } from '../../../context/FormContext';
import { on } from 'events';
import { usePlaidLink, PlaidLinkOptions } from 'react-plaid-link';
import SystemAccountReference from '../../../interfaces/SystemAccountReference';


interface IProps {
  component:ISelectOne;
  parentAlias:string;
  validationHandler(alias:string, isValid:boolean):void;
}

const AccountVerification:FunctionComponent<IProps> = props => {

  const formContext = useContext(FormContext);
  
  let [checkedValue, setCheckedValue] = useState<string>(getValue());
  let [isValid, setIsValid] = useState<boolean>();
  let [plaidToken, setPlaidToken] = useState<string>('');
  let [systemAccountReference, setSystemAccountReference] = useState<SystemAccountReference>();

  const fetchPlaidLinkToken = React.useCallback(() => {
    if(props.component.advanced === 'bank-verification') {
      
    axios({
      "method": "GET",
      //"url": "http://dev.firetap.com/formbuilder-services/getToken",
      //"url": "http://localhost:8080/formbuilder-api/getToken",
      "url" : `//${process.env.REACT_APP_CIF_SERVICE_BASEPATH}/getToken`,
      "headers": {
        'Access-Control-Allow-Origin' : '*',
        'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        }
    })
    .then((response) => {
      //console.log(response.data);
      let linkToken:string = response.data.linkToken;
      setPlaidToken(linkToken);
    })
    .catch((error) => {
      //console.log(error)
    })
    
  }
  }, [])
  
  const onSuccess = useCallback((token, metadata) => {
    // send token to server
    //console.log(token);
    //console.log(metadata);
    setCheckedValue('instant');
    if(formContext.updateFormData) {
       let sysAccount:SystemAccountReference = {} as SystemAccountReference;
       sysAccount.id = metadata.account.id;
       sysAccount.name = metadata.account.name;
       sysAccount.mask = metadata.account.mask;
       setSystemAccountReference(sysAccount);
       formContext.updateFormData("system.account.id", metadata.account.id);
       formContext.updateFormData("system.account.name", metadata.account.name);
       formContext.updateFormData("system.account.mask", metadata.account.mask);
    }
    axios({
      "method": "POST",
      //"url": "http://dev.firetap.com/formbuilder-services/getAccountDetails",
      //"url": "http://localhost:8080/formbuilder-api/getAccountDetails",
      "url": `//${process.env.REACT_APP_CIF_SERVICE_BASEPATH}/getAccountDetails`,
      "headers": {
    'Access-Control-Allow-Origin' : '*',
    'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    },
      "data": {
        item : metadata.account_id,
        publicToken : token
      }
    })
    .then((response) => {
      //console.log(response.data);
      if(formContext.updateFormData) {
         formContext.updateFormData(props.parentAlias + "." + props.component.alias, 'instant');
      }
      
    })
    .catch((error) => {
      //console.log(error)
    })
  
  }, []);

  const onExit = useCallback(() => {
    
    if(formContext.updateFormData && formContext.updateForm) {
      //console.log('closed');
      //formContext.updateFormData(props.parentAlias + "." + props.component.alias, '');
      //formContext.updateForm();
    }
    
   //setCheckedValue('');
    //setTimeout(validate, 1);
  }, []);
  

  

  //if(props.component.advanced === 'bank-verification') {
    const config:PlaidLinkOptions = {
      token: plaidToken,
      onSuccess : onSuccess,
      onExit : onExit,
      // ...
    };

  //}
  
  const { open, ready, error } = usePlaidLink(config);
  
  React.useEffect(() => {
    if(props.component.advanced === 'bank-verification') {
    fetchPlaidLinkToken()
    }
  }, [fetchPlaidLinkToken])
  
  

  function onValueChange(value:string) {
    if(formContext.updateFormData) {
      if(value === 'instant') {
          open();
          formContext.updateFormData(props.parentAlias + "." + props.component.alias, '');
          setCheckedValue('');
        } else {
          formContext.updateFormData(props.parentAlias + "." + props.component.alias, value);
          setCheckedValue(value);
        }
    }
  }

  function getAlias():string {
    return props.parentAlias + "." + props.component.alias;
  }
 
  function getValue():string {
    
    let value = formContext.formData?.get(getAlias());
    
    if(value) {
      return value;
    } else {
      return '';
    }
  }

  function validate() {
    
    let isFieldValid = true;
    
   //console.log("checked value is: " + checkedValue);
      if(!checkedValue || !checkedValue.trim()) {
        isFieldValid = false;  
        
      }
   //   console.log(isFieldValid);
    //console.log('payment validator ran')
      setIsValid(isFieldValid);
      props.validationHandler(getAlias(), isFieldValid);
  }

  React.useEffect(() => {
   validate();
  }, [formContext.formRevision])

  function getLayout(formItem:ISelectOne) {
    switch(formItem.layout) {
        case 'big-image-buttons': {
            return <FormItemBigImageButtons checkedValue={checkedValue} parentAlias={getAlias()} onValueChange={onValueChange} component={formItem} />;
        }
        case 'big-vertical-buttons' : {
            return <FormItemBigVerticalButtons checkedValue={checkedValue} parentAlias={getAlias()} onValueChange={onValueChange} component={formItem} />;
        }
    }
    return <div></div>
}

function onReset() {
  setSystemAccountReference(undefined);
  if(formContext.updateFormData) {
     formContext.updateFormData(props.parentAlias + "." + props.component.alias, '');
  }
  setCheckedValue('');
}

  return (
    <Fragment>
      {systemAccountReference && 
      <div className="account-selection">
         <h2>Verified Account Information</h2>
         <span className="account-name">{systemAccountReference.name} </span> 
         <span className="account-mask">Account Number Ending In: {systemAccountReference.mask}</span>
         <button onClick={onReset}>reset</button>
         <div className="verify-image"><img src="/iconfinder_Check_circle_2202263.svg" /></div>
      </div>}
    {!systemAccountReference && getLayout(props.component)}
    </Fragment>
  );
}

export default AccountVerification;