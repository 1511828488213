import React, { Component, FunctionComponent, useEffect, useState, useContext } from 'react';
import axios from 'axios';
import FormItemRenderer from '../../FormItemRenderer/FormItemRenderer';
import IFieldSetExpandableList from '../interfaces/FieldSetExpandableList';
import IFieldSet from '../../FormItem/interfaces/FieldSet';
import { FormContext } from '../../../context/FormContext';
import './FieldSetExpandableList.scss';
import { cpuUsage } from 'process';

interface IProps {
  component:IFieldSetExpandableList;
  parentAlias:string;
  formDirty:boolean;
  validationHandler(alias:string, isValid:boolean):void;
  removeValidationHandler(alias:string):void;

}



const FieldSetExpandableList:FunctionComponent<IProps> = props => {
  let [itemCount, setItemCount] = useState<number>(props.component.initial || 1);
  let [fieldSets, setFieldSets] = useState<IFieldSet[]>(getFirstFieldSet());
  let [percentSplit, setPercentSplit] = useState<number[]>([]);
  

  const formContext = useContext(FormContext);

  function expandList() {
    let itemNumber = itemCount + 1;
    setItemCount(itemNumber);
    let fieldSet:IFieldSet =  JSON.parse(JSON.stringify(props.component.formItems[0]));
    fieldSet.alias = props.component.alias + '-' + (itemNumber);
    const newFieldSets = [...fieldSets, fieldSet];
    
    setFieldSets(newFieldSets);
  }

  function getFirstFieldSet():IFieldSet[] {
    let initItemCount = 0;
    let newFieldSets = [];
    for (let i = 0; i < (props.component.initial || 1); i++) {
      initItemCount = initItemCount + 1;
      let fieldSet:IFieldSet =  JSON.parse(JSON.stringify(props.component.formItems[0]));
      fieldSet.alias = props.component.alias + '-' + initItemCount;
      newFieldSets.push(fieldSet);
    }
   // setItemCount(initItemCount);
    
    return newFieldSets;
  }

  React.useEffect(() => {
    if (fieldSets.length > 0) {
      //console.log(fieldSets.length);
      let percent = 100 / fieldSets.length;
      let remainder = 100 % fieldSets.length;
      let percentArray = [];
      for (let i = 0; i < fieldSets.length; i++) {
        percentArray.push(Math.floor(percent));
      }
      for (let j = 0; j < remainder; j++) {
        percentArray[j] = percentArray[j] + 1;
      }
      setPercentSplit([... percentArray]);
    }
  }, [fieldSets])

  function removeItem(index:number) {
    let updatedArray = Array.from(fieldSets);
    let deletedElement = updatedArray.splice(index, 1)[0];
    if (formContext.removeFormData) {
      let key = props.parentAlias + '.' + props.component.alias + '.' + deletedElement.alias;
      formContext.removeFormData(key);
      props.removeValidationHandler(key)
    }
    let itemNumber = itemCount - 1;
    setItemCount(itemNumber);
    setFieldSets([... updatedArray]);
  }

  return (
    <div className="field-set-expandable-list">
       {fieldSets.map((formItem:any, index:number) =>
            <div className="expandable-wrapper" key={'item-' + formItem.alias}>
              {index != 0 && <button className="remove-button" onClick={() => removeItem(index)}>Remove</button>}
              <FormItemRenderer formDirty={props.formDirty} removeValidationHandler={props.removeValidationHandler} validationHandler={props.validationHandler} count={index + 1} parentAlias={props.parentAlias + '.' + props.component.alias} formItem={formItem} percentage={percentSplit[index] || undefined} />
            </div>
        )}
        
        {itemCount < (props.component.max || 10) && <button className="add-button" onClick={expandList}>{props.component.expandButtonLabel}</button>}
    </div>
  );
}

export default FieldSetExpandableList;