import React, { Fragment, FunctionComponent, useContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import Modal from '../../Modal';
import './WatersEdgeTheme.scss';

interface IProps {
  resetApp():void;
}


const WatersEdgeTheme:FunctionComponent<IProps> = props => {

  let [showHelp, setShowHelp] = useState<boolean>(false);

  return (
    <>
    <Fragment> 
    <div className="app-wrapper watersedge">
    <div className="App">
      <div className="header">
        <div className="header-body">
          <img src="../waters-edge-wide.png" />
          <div className={"header-buttons"}>
            {<a onClick={props.resetApp}>Reset</a>} <a onClick={() => setShowHelp(true)} className='dark-button'>Help</a>
          </div>
        </div>
         
      </div>
      
    </div>
       {props.children}
    </div>
    <div className="page-footer">
    WatersEdge securities are subject to certain risk factors as described in our Offering Circular and are not FDIC or SIPC insured. NOTICE: This is not an offer to sell or solicit securities. WatersEdge offers and sells securities only in states where authorized. This offering is made solely by our Offering Circular. <a href="https://www.watersedge.com/privacy/">Privacy Policy</a> & <a href="https://www.watersedge.com/kingdom-investments/terms-and-conditions/">Term and Conditions</a>
    </div>
    </Fragment>
    {showHelp && ReactDOM.createPortal(
             
             <Modal alias={'modal-help'} modalCloseHandler={()=> setShowHelp(false)} />
             
            ,
      document.body
            )}
    </>
    )
}

export default WatersEdgeTheme;