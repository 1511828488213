import React, { Component, Fragment, FunctionComponent, useContext, useEffect, useState } from 'react';

import './Alert.scss';
import IAlert from '../interfaces/Alert';
import {FormContext} from '../../../context/FormContext';
import KeyReplace from '../../KeyReplace';


interface IProps {
  component:IAlert;
}

const Alert:FunctionComponent<IProps> = props => {
  
  const formContext = useContext(FormContext);
  

function replaceURLs(message:string) {
  if(!message) return "";
 
  var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
  return message.replace(urlRegex, function (url) {
    var hyperlink = url;
    if (!hyperlink.match('^https?:\/\/')) {
      hyperlink = 'http://' + hyperlink;
    }
    return '<a href="' + hyperlink + '" target="_blank" rel="noopener noreferrer">' + url + '</a>'
  });
}


  return (
    <div className={"alert " + (props.component.style ? props.component.style : '')}>
      <p>
        <KeyReplace string={replaceURLs(props.component.content)} />
        </p>
    </div>
  );
}

export default Alert;