import React, { Component, FunctionComponent, useEffect, useState, useContext } from 'react';
import axios from 'axios';
import FormItemRenderer from '../../FormItemRenderer/FormItemRenderer';
import IDependancyChoices from '../interfaces/DependancyChoices';
import IFieldSet from '../../FormItem/interfaces/FieldSet';
import { FormContext } from '../../../context/FormContext';
import './DependancyChoices.scss';

interface IProps {
  component:IDependancyChoices;
  parentAlias:string;
  formDirty:boolean;
  validationHandler(alias:string, isValid:boolean):void;
  removeValidationHandler(alias:string):void;

}



const DependancyChoices:FunctionComponent<IProps> = props => {
    const formContext = useContext(FormContext);
  
    let [itemCount, setItemCount] = useState<number>(1);
  let [fieldSets, setFieldSets] = useState<IFieldSet[]>(getFieldSets());
  let [percentSplit, setPercentSplit] = useState<number[]>([]);
  

  


  function getFieldSets():IFieldSet[] {
    let formData = undefined;
    if (formContext) {
        formData = formContext.formData;
    }
    
    
    let key = props.component.dependancyKey;
    let count = 0
    let keyList:string[] = [];
    if(formData) {
        
        
        let didntFindSomething = false;
        loopmain:
        while (true && !didntFindSomething) {
            count = count +1;
            let lookup =  key.replace('*', String(count));
            //console.log(lookup);
            
            loop1:
            for (let [key, value] of formData.entries()) {
                if (key.startsWith(formContext.formAlias + "." + lookup)) {
                    keyList.push(lookup);
                    //console.log('found it')
                    break loop1;
                }
            }
            //didntFindSomething = true;
            if (count == 10) {
                break;
            }
        }
    }
    //console.log(keyList);

    let initItemCount = 0;
    let newFieldSets = [];
    for (let i = 0; i < keyList.length; i++) {
      initItemCount = initItemCount + 1;
      let fieldSet:IFieldSet =  JSON.parse(JSON.stringify(props.component.formItems[0]).replaceAll('{dependancy}', keyList[i]));
      fieldSet.alias = props.component.alias + '-' + initItemCount;
      newFieldSets.push(fieldSet);
    }
   // setItemCount(initItemCount);
    
    return newFieldSets;
  }

  React.useEffect(() => {
    setFieldSets(getFieldSets());
   }, [formContext.formRevision])


  return (
    <div className="field-set-expandable-list">
       {fieldSets.map((formItem:any, index:number) =>
            <div className="expandable-wrapper" key={'item-' + formItem.alias}>
              <FormItemRenderer formDirty={props.formDirty} removeValidationHandler={props.removeValidationHandler} validationHandler={props.validationHandler} count={index + 1} parentAlias={props.parentAlias + '.' + props.component.alias} formItem={formItem} percentage={percentSplit[index] || undefined} />
            </div>
        )}
        
    </div>
  );
}

export default DependancyChoices;