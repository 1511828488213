import React, { Component, Fragment, FunctionComponent, useContext, useEffect, useState } from 'react';
import './Modal.scss';


interface IProps {
  
}

const Help:FunctionComponent<IProps> = props => {
  
  


  return (
    <div className="modal">
        <h1>Have Questions? We Can Help...</h1>
        <div className="scroll-wrapper">
          <div className="scroll-area">
            <p>Please contact our Kingdom Investments team at <a href="mailto:investments@WatersEdge.com">investments@WatersEdge.com</a> or call <a href="tel:1-800-949-9988">800-949-9988</a>.</p>
          </div>
        </div>
        
    </div>
  );
}

export default Help;