import React, { FunctionComponent, Fragment, useContext, useEffect, useState, useRef, ChangeEvent } from 'react';
import Product from '../../RateChart/interface/Product';
import InvestmentProduct from '../interfaces/InvestmentProduct';

interface IProps {
    investmentItem:InvestmentProduct;
    investmentOptions:Product[];
    updateAmount(value:string):void;
}

const InvestmentAmountInput: FunctionComponent<IProps> = props => {

    let [isTouched, setIsTouched] = useState<boolean>(false);

    function isAmountValid(product:InvestmentProduct):boolean {
        let valid = true;
        let minimumInvestment = parseFloat(getMinimum(product).replace(/,/g, ''));
        let amount = parseFloat((product.amount || "0").replace(/,/g, ''));
        
        if (amount < minimumInvestment) {
            valid = false;
        }
        return valid;
      }


    function addComma(string:string) {
        return string.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    

    function onAmountChange(e:React.ChangeEvent<HTMLInputElement>|ChangeEvent<HTMLTextAreaElement>) {
        let value = e.target.value;
        props.updateAmount(addComma(value));
    }


      function getMinimum(investment:InvestmentProduct) {
        
        if(investment.product) {
          let min = investment.product.rates[0].minimum;
          return addComma(String(min));
        } else {
          return "";
        }
      }
  
      
    return (
        <div className={'investment-product-amount ' + (props.investmentItem.product ? '' : 'disabled ') + (isTouched && !isAmountValid(props.investmentItem) ? 'error' : '')}>
            <label><span className='block-label'>Amount</span>
                <input disabled={props.investmentItem.product ? false : true} type='text' inputMode={"numeric"} pattern="[0-9]*" placeholder={props.investmentItem.product ? getMinimum(props.investmentItem) + ' Minimum' : ''} value={props.investmentItem.amount || ""} onChange={(event) => onAmountChange(event)} />
            </label>
        </div>
    );
}

export default InvestmentAmountInput;