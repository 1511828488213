import React, { Component, FormEvent, FunctionComponent, useContext, useEffect, useState } from 'react';

import './EmailSignup.scss';
import IEmailSignup from '../interfaces/EmailSignup';
import IEmailSignupForm from '../../../interfaces/EmailSignup';
import StandardInput from '../StandardInput';
import IStandardInput from '../interfaces/StandardInput';
import { FormContext } from '../../../context/FormContext';
import axios from 'axios';

interface IProps {
  component:IEmailSignup;
  parentAlias:string;
}

const EmailSignup:FunctionComponent<IProps> = props => {
  
    const formContext = useContext(FormContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [status, setStatus] = useState<string>('waiting');
    


  function validationHandler(alias:string, isValid:boolean):void {
    //console.log(isValid);
  }

  let emailBox:IStandardInput = {} as IStandardInput;
  
  emailBox.type = 'email';
  emailBox.alias = 'email-signup';
  emailBox.label = 'Email Address';
  emailBox.errorMessage ="Please provide a valid email address";
  emailBox.format = "email";
  emailBox.required = true;
  emailBox.hideLabel = true;
  emailBox.defaultValue = formContext.formData?.get(props.component.defaultValue || '');
  
  function getSessionString(key:string):string {
    let string = sessionStorage.getItem(key)
    return string ? JSON.parse(string) : '';
  }

  function onSubmit(event:FormEvent) {
    event.preventDefault();
    setLoading(true);
    //console.log(props.parentAlias + "." + props.component.alias + ".email-signup");
    let emailAddress = formContext.formData?.get(props.parentAlias + "." + props.component.alias + ".email-signup")
    let submission:IEmailSignupForm = {} as IEmailSignupForm;
    submission.emailAddress = emailAddress || '';
    submission.formAction = props.component.formAction; 
    submission.formKey = getSessionString('formKey');
    axios({
        "method": "POST",
        "url" : `//${process.env.REACT_APP_CIF_SERVICE_BASEPATH}/handleEmailSignup`,
        "data": submission
      })
      .then((response) => {
        //console.log(response.data);
        setStatus('success');
        setLoading(false);
        //window.history.pushState({currentStep: currentStep}, finalPage.name, finalPage.alias);
      })
      .catch((error) => {
        console.log(error)
        setStatus('error');
        setLoading(false);
        //window.history.pushState({currentStep: currentStep}, finalPage.name, finalPage.alias);
      })
  }
  
  
  return (
    <div className="email-signup">
        <h2>{props.component.label}</h2>
        {status === 'waiting' && !loading && <form noValidate onSubmit={onSubmit}>
            
            <div className='email-signup-layout'>
        <StandardInput parentAlias={props.parentAlias + '.' + props.component.alias} component={emailBox} formDirty={false} validationHandler={validationHandler} isDisabled={false} />
        <button disabled={loading} type='submit'>{props.component.buttonLabel}</button>
        </div>
        </form>}
        {loading && <div className='loading-area'>
            <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
        </div>}
        {status === 'success' && <div>
            <p>{props.component.successMessage}</p>    
        </div>}
    </div>
  );
}

export default EmailSignup;