import React, { FunctionComponent, useEffect, useState, useContext } from 'react';
import axios from 'axios';
import './RadioButton.scss';
import FormItemChoice from '../../../interfaces/FormItemChoice';
import CheckBox from '../CheckBox';
import { FormContext } from '../../../context/FormContext';
import KeyReplace from '../../KeyReplace';

interface IProps {
  choice:FormItemChoice;
  name:string;
  count:number;
  checkedValue:string;
  onValueChange(value:string):void;
}

const RadioButton:FunctionComponent<IProps> = props => {

  const formContext = useContext(FormContext);

  const [hover, setHover] = useState<boolean>(false);

  function addSystemValues() {
    if (props.choice.hasOwnProperty('systemValue') && formContext.updateFormData) {
      let systemValues:string[] | undefined = props.choice.systemValue?.split(',');
      systemValues?.forEach((value:string) => {
      let systemValue:string[] | undefined = value?.split('|');
      if(systemValue) {
        if(formContext.updateFormData) {
          formContext.updateFormData("system." + systemValue[0] ,systemValue[1]);
        }
      }
    })
      
      
    }
    
  }

  function onChangeHandler(e:React.ChangeEvent<HTMLInputElement>) {
    addSystemValues();
    props.onValueChange(e.target.value);
  }

    function getId():string {
        return props.name + props.count;
    }
    

    function onClick() {
      if (!props.choice.disabled) {
        addSystemValues();
        props.onValueChange(props.choice.value);
      }
      
    }

    

  return (
    <div className={"radio-button " + (props.choice.value === props.checkedValue ? ' selected' : '') + (hover ? ' hover' : '') + (props.choice.disabled ? ' disabled' : '')} onClick={onClick} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
        <input onChange={onChangeHandler} type="radio" value={props.choice.value} checked={props.choice.value === props.checkedValue} id={getId()} name={props.name} />
        <label htmlFor={getId()}><KeyReplace string={props.choice.label || ''} /> <span className="sub-label"><KeyReplace string={props.choice.subLabel || ''} /></span> {props.choice.extraInfo && <span className="extra-info"><KeyReplace string={props.choice.extraInfo} /></span>}{props.choice.disabledText && <span className="disabled-info"><KeyReplace string={props.choice.disabledText} /></span>}</label>
        
    </div>
  );
}

export default RadioButton;