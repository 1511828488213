import React, { Fragment, FunctionComponent, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import './Step.scss';
import IFormSection from '../../interfaces/FormSection';
import FormItemRenderer from '../FormItemRenderer/FormItemRenderer';
import FormSection from '../FormSection';
import { FormContext } from '../../context/FormContext';
interface IProps {
  formSection:IFormSection;
  parentAlias:string;
  maxStep:number;
  currentStep:number;
  sectionActive:boolean;
  updateFormError(key:string, value:boolean):void;
}


const Step:FunctionComponent<IProps> = props => {

    const formContext = useContext(FormContext);

    let [isValid, setIsValid] = useState<boolean>(false);
    const [formDirty, setFormDirty] = useState<boolean>(false);
    const [firstError, setFirstError] = useState<string>('');

    function validateForm(isFormValid:boolean):void {
      setIsValid(isFormValid);
    }
  
    function replaceText(text:string | undefined):string | undefined {
      let newString = text;
      if (newString !== undefined && formContext.formData && text?.includes("{")) {
        Array.from(formContext.formData).forEach((value:[string,string]) => 
          newString = newString!.replaceAll("{" + value[0] + "}", value[1])
        )
      }
    
      return newString;
    }

    function onNextHandler() {
      if(isValid) {
        if (props.currentStep + 1 != props.maxStep) {
          if(formContext.nextStep) {
            formContext.nextStep();
          }
        } else {
          if(formContext.setFinished) {
            formContext.setFinished(replaceText(props.formSection.finalAction) || 'submit');
          }
          
        }
      } else {
        if(!isValid && props.sectionActive) {
          const item = document.getElementById(firstError);
          
          //console.log(item?.getBoundingClientRect().y);
          if(item) {
            // window.scrollTo({
            //   top: item.getBoundingClientRect().y,
            //   behavior: 'smooth'
            // });
            item.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
            
          }
          setFormDirty(true);
      }
      
      
      
      
    }

    React.useEffect(() => {
      props.updateFormError(props.formSection.alias, isValid);
     }, [isValid]);

     
    

    

   
  return (
    <Fragment>
    <div className="step">
        <FormSection setFirstError={setFirstError} sectionActive={props.sectionActive} formDirty={formDirty}  isFormValid={isValid} formValidator={validateForm} parentAlias={props.parentAlias} formSection={props.formSection} />
        <div className="button-bar">
          <button className={"next-button " + (!isValid ? 'disabled' : '')} onClick={onNextHandler} >{props.formSection.buttonLabel ? props.formSection.buttonLabel : 'Continue'}</button>
          {props.currentStep != 0 && <button className="previous-button" onClick={formContext.prevStep}>Previous</button>}
        </div>
    </div>
    </Fragment>
  );
}

export default Step;