import React, { FunctionComponent, Fragment, useContext, useEffect, useState } from 'react';
import FormMap from '../../interfaces/FormMap';
import IFormSection from '../../interfaces/FormSection';
import FormSection from '../FormSection/FormSection';
import ReactHtmlParser from 'react-html-parser'; 
import {FormContext} from '../../context/FormContext';
import './Modal.scss';
import AcknowledgementUs from '../ChurchInvestorsFund/AcknowledgementUs';
import AcknowledgementCa from '../ChurchInvestorsFund/AcknowledgementCa';
import Help from '../ChurchInvestorsFund/Help';


interface IProps {
  alias:string;
  modalCloseHandler():void;
}



const Modal:FunctionComponent<IProps> = props => {

  const formContext = useContext(FormContext);
      
  return (
    <div className="modal-overlay">
        <div className="modal-overlay-background" onClick={() => props.modalCloseHandler()}></div>
        <div className={"modal-wrapper " + (props.alias === 'modal-help' ? 'small' : '')}>
            
            <div className={"modal-content"}>
                {props.alias === "modal-help" && <Help />}
                {props.alias === "modal-us-acknowledgement" && <AcknowledgementUs />}
                {props.alias === "modal-ca-acknowledgement" && <AcknowledgementCa />}
                <div className="modal-footer"><button onClick={() => props.modalCloseHandler()}>Close</button></div>
            </div>
            <a className="modal-close" onClick={() => props.modalCloseHandler()}>Close</a>
        </div>
    </div>
  );
}

export default Modal;